@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sub {
  font-family: 'Noto Sans', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  color: #2185d0 !important;
  font-family: 'Poppins', sans-serif !important;
}

h1 {
  font-size: 35px !important;
}

#root {
  height: 100%;
}

